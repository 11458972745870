import {graphql} from "gatsby"
import React from "react"
import Layout from "../Page"
import Login from "../../components/component-login"

const LoginPage = ({ data: { silverStripeDataObject }}) => {
    const { bannerTitle, bannerSubTitle, bannerImageURL} = silverStripeDataObject.Page;
    return (
      <Layout>
        <Login/>
      </Layout>
    )
}
export default LoginPage
export const pageQuery = graphql`
    query ($link: String!) {
            silverStripeDataObject(link: { eq: $link }) {
                Page{
                    bannerTitle
                    bannerSubTitle
                    bannerImageURL
                }
            }

    }
`;