import React from "react"
import { handleLogin, handleLoginAsUser, isLoggedIn } from "../services/auth"
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import config from "../config/config"
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import AppleLogin from 'react-apple-login'
import { parseJwt } from "../services/ultility";

class Login extends React.Component {
  state = {
    email: ``,
    password: ``,
    remember: 0,
    fbid: ``,
    name: ``,
    accessToken: ``
  }
  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleSubmit = event => {
    event.preventDefault();
    var data = this.state;
    handleLogin(data);
  }
  render() {
    const responseFacebook = (response) => {
      if (typeof response === 'object') {
        let _data = {
          email: response.email,
          name: response.name,
          method: 'facebook',
          fbid: response.id,
          accessToken: response.accessToken
        };
        handleLogin(_data);
      }
    }
    const responseGoogle = (response) => {
      if (typeof response === 'object') {
        let google_profile = response.profileObj
        if (typeof google_profile === 'object') {
          let _data = {
            email: google_profile.email,
            name: google_profile.name,
            method: 'google',
            ggid: google_profile.googleId,
            accessToken: response.accessToken
          };
          handleLogin(_data);
        }
      }
    }
    const responseApple = (response) => {
      if (typeof response === 'object') {
        const authObject = response.authorization
        const user = response.user
        if (authObject) {
          const idToken = authObject.id_token
          const data = parseJwt(idToken)
          if (data && data.email) {
            let is_private_email = data.is_private_email
            let name = data.email
            if(is_private_email){
                name = 'guest'
            }else{
                if(typeof user === 'object'){
                    name = user.name.firstName
                }
            }
            let _data = {
                email: data.email,
                name: name,
                method: 'apple',
                appleid: data.sub,
                accessToken: data.sub,
                is_private_email: is_private_email
            };
            handleLogin(_data)
          }
        }
      }
    }
    const appleRedirectURI = config.frontend + '/login/'    
    const search = typeof window !== "undefined" ? window.location.search : "/"
    const params = new URLSearchParams(search)
    var email = params.get("email")
    var key = params.get("key")
    if (email && key) {
      let _data = {
        email: email,
        password: key,
        remember: 0,
      };
      handleLoginAsUser(_data);
    } else {
      if (isLoggedIn()) {
        if (typeof window !== 'undefined') {
          window.location.href = "/account/";
        }
      }
    }
    return (
      <section className="section pt-4">
        <div className="container bg-block-wrap">
          <div className="bg-block">
            <div className="bg-item">
              <div className="bg-item-inner">
                <img src="/img/login/1.png" alt="" />
              </div>
            </div>
            <div className="bg-item">
              <div className="bg-item-inner">
                <img src="/img/login/2.png" alt="" />
              </div>
            </div>
            <div className="bg-item">
              <div className="bg-item-inner">
                <img src="/img/login/3.png" alt="" />
              </div>
            </div>
            <div className="bg-item">
              <div className="bg-item-inner">
                <img src="/img/login/4.png" alt="" />
              </div>
            </div>
            <div className="bg-item lg-size">
              <div className="bg-item-inner">
                <img src="/img/login/5.png" alt="" />
                <p>Choose<br></br>
                  a lifestyle,<br></br>
                  not a<br></br>
                  floor plan
                </p>
              </div>
            </div>
            <div className="bg-wrap">
              <div className="bg-item">
                <div className="bg-item-inner">
                  <img src="/img/login/6.png" alt="" />
                </div>
              </div>
              <div className="bg-item">
                <div className="bg-item-inner">
                  <img src="/img/login/7.png" alt="" />
                </div>
              </div>
              <div className="bg-item">
                <div className="bg-item-inner">
                  <img src="/img/login/8.png" alt="" />
                </div>
              </div>
              <div className="bg-item">
                <div className="bg-item-inner">
                  <img src="/img/login/9.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="row no-gutters bg-inner">
            <div className="col-sm-10 offset-sm-1 col-lg-8 offset-lg-3 col-xl-6 offset-xl-5">
              <div className="login-box">
                <h2 className="text-center">Welcome back to Birdy</h2>
                <Form className="form-content" method="post"
                  onSubmit={event => {
                    this.handleSubmit(event)
                  }}>
                  <div id="error-message" className="form-group alert alert-danger" style={{ display: 'none' }}></div>
                  <div className="form-group">
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      id="email"
                      placeholder="Email"
                      required onChange={this.handleUpdate}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      minLength="6"
                      name="password"
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Password"
                      required onChange={this.handleUpdate}
                    />
                  </div>
                  <div className="text-center py-3">
                    <div className="custom-control custom-checkbox remember-checkbox">
                      <input
                        name="remember"
                        type="checkbox"
                        className="custom-control-input"
                        id="remember"
                        onChange={this.handleUpdate}
                      />
                      <label className="custom-control-label" htmlFor="remember">
                        Remember my email address when I next login
                      </label>
                    </div>
                  </div>
                  <div className="modal-button-wrap">
                    <button
                      type="submit"
                      className="btn btn-black btn-w210 btn-lg">
                      LOGIN
                    </button>
                    <span>OR</span>
                  </div>
                  <GoogleLogin
                    clientId={config.google_client_id}
                    render={renderProps => (
                      <a href={`#`} onClick={renderProps.onClick} className={`btn btn-block btn-lg btn-google`} disabled={renderProps.disabled}>Continue with Google</a>
                    )}
                    buttonText="Continue with Google"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    className="btn btn-block btn-lg btn-google"
                    icon={false}
                  />
                  <FacebookLogin
                    appId={config.fb_app_id}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    cssClass="btn btn-block btn-lg btn-fb"
                    icon=""
                    textButton="Continue with Facebook"
                  />
                  <AppleLogin
                    render={renderProps => (
                      <a href={`#`} onClick={renderProps.onClick} className={`btn btn-block btn-lg btn-apple`} disabled={renderProps.disabled}>Continue with Apple</a>
                    )}
                    clientId={config.apple_client_id}
                    redirectURI={appleRedirectURI}
                    usePopup={true}
                    callback={responseApple}
                    scope="name email"
                    state=""
                    responseType="code"
                    responseMode="form_post"
                  />

                  <div className="text-center">
                    <p>
                      <a href="/register" className="already-member">Don't have an account? <span>Sign up</span></a>
                      <br />
                      <a href="/forgot-password" className="already-member">Forgot your password?</a>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default Login